/**
 * Used for linking messages and errors to a feature.
 */
export enum Feature {
  CreditCardElements = 'CreditCardElements',
  DigitalWallets = 'DigitalWallets',
  SingleLineCardElement = 'SingleLineCardElement',
}

/**
 * Logs a message to the console.
 */
const log = (...data: any): void =>
  console.log('%c Olo Pay ', 'background: #25B3E2; color: #ffffff', ...data);

/**
 * Throws an error (stopping execution). Use sparingly if ever.
 */
const throwError = (message: string | undefined): void => {
  throw new Error(`Olo Pay Error: ${message}`);
};

/**
 * Logs an error (continuing execution).
 */
const error = (...data: any): void => {
  console.error('%c Olo Pay Error ', 'background: #FF2A53; color: #ffffff', ...data);
};

const message = {
  log,
  error,
  throw: throwError,
};

export default message;
