import { ElementCSSProperties, ElementStyle, ElementStyleVariant } from '../types';

const defaultBase: ElementCSSProperties = {
  color: 'inherit',
  backgroundColor: 'inherit',
  fontFamily: 'inherit',
  fontSize: '1.8rem',
  fontSmoothing: 'inherit',
  fontStyle: 'inherit',
  fontVariant: 'inherit',
  fontWeight: 'inherit',
  iconColor: '#25B3E2',
  lineHeight: 'inherit',
  letterSpacing: 'inherit',
  textAlign: 'inherit',
  textDecoration: 'inherit',
  textShadow: 'inherit',
  textTransform: 'inherit',
};

const defaultPseudos: ElementStyleVariant = {
  ':hover': defaultBase,
  ':focus': defaultBase,
  '::placeholder': defaultBase,
  '::selection': defaultBase,
  ':-webkit-autofill': defaultBase,
  ':disabled': defaultBase,
  '::-ms-clear': { ...defaultBase, display: 'string' },
};

export const defaultStyles: ElementStyleVariant = {
  ...defaultBase,
  ...defaultPseudos,
};

export const defaultStyleObject: ElementStyle = {
  base: { ...defaultStyles },
  complete: { ...defaultStyles },
  empty: { ...defaultStyles },
  invalid: { ...defaultStyles },
};
