const isElement = (item: any): boolean => item.nodeType === 1 && item.matches('*');

const isEmpty = (thing: {}) => {
  if (thing && typeof thing === 'object' && Object.keys(thing).length === 0) {
    return true;
  }
  if (Array.isArray(thing) && thing.length === 0) {
    return true;
  }
  return false;
};

function isSelector(item: any): boolean {
  const s = String(item);
  return s.startsWith('[data-') || s.startsWith('#') || s.startsWith('.');
}

/**
 * Checks the `window` for `@olo/pay` and `Stripe` which can indicate whether
 * the library is being loaded via ESM or UMD.
 *
 * @returns True if Olo Pay and Stripe were loaded to the window object.
 */
const onWindow = (): boolean => Boolean('@olo/pay' in window && window.Stripe);

export { isElement, isEmpty, isSelector, onWindow };
