import { isElement } from './helpers';
import message from './message';

type Target = string | HTMLElement;

export default function find(target: Target): HTMLElement | null {
  if (isElement(target)) {
    const element = target as HTMLElement;
    return element.isConnected ? element : null;
  }

  try {
    return document.querySelector(target as string) as HTMLElement;
  } catch (error) {
    message.error(`No element marked with ${target} found`);
    return null;
  }
}
