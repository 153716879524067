export const TEST_PAYMENT_METHOD = {
  paymentMethod: {
    id: 'pm_visa',
    object: 'payment_method',
    billing_details: {
      address: {
        city: 'New York',
        country: 'US',
        line1: 'One World Trade Center',
        line2: null,
        postal_code: '10006',
        state: 'NY',
      },
      email: 'olopaytest@test.com',
      name: 'Test Mode',
      phone: '4444444444',
    },
    card: {
      brand: 'visa',
      checks: {
        address_line1_check: null,
        address_postal_code_check: null,
        cvc_check: null,
      },
      country: 'US',
      exp_month: 12,
      exp_year: 2031,
      funding: 'credit',
      generated_from: null,
      last4: '1234',
      networks: {
        available: ['visa'],
        preferred: null,
      },
      three_d_secure_usage: {
        supported: true,
      },
      wallet: {
        dynamic_last4: '1234',
        google_pay: {},
        type: 'google_pay',
      },
    },
    created: 1628283848,
    customer: null,
    livemode: false,
    type: 'card',
  },
};
